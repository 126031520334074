<script lang="ts">
	import Toast from './Toast.svelte';
	import { dismissToast, toasts } from '$store/toast';
</script>

{#if $toasts}
	<section>
		{#each $toasts as toast (toast.id)}
			<Toast
				type={toast.type}
				dismissible={toast.dismissible}
				on:dismiss={() => dismissToast(toast.id)}>{toast.message}</Toast
			>
		{/each}
	</section>
{/if}

<style lang="postcss">
	section {
		@apply z-5000 fixed left-0 right-0 top-2 flex w-full flex-col items-center justify-center;
	}
</style>
