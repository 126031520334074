<script lang="ts">
	import { createEventDispatcher } from 'svelte';
	import { fade } from 'svelte/transition';
	import SuccessIcon from '$lib/icons/toast/success-icon.svelte';
	import ErrorIcon from '$lib/icons/toast/error-icon.svelte';
	import InfoIcon from '$lib/icons/toast/info-icon.svelte';
	import CloseIcon from '$lib/icons/toast/close-icon.svelte';
	import type { ToastType } from '$store/toast';

	const dispatch = createEventDispatcher();

	export let type: ToastType = 'error';
	export let dismissible = true;
</script>

<article class={type} role="alert" transition:fade|global>
	{#if type === 'success'}
		<SuccessIcon width="1.1em" />
	{:else if type === 'error'}
		<ErrorIcon width="1.1em" />
	{:else if type === 'warning'}
		<InfoIcon width="1.1em" />
	{:else}
		<InfoIcon width="1.1em" />
	{/if}

	<div class="text">
		<slot />
	</div>

	{#if dismissible}
		<button class="close" on:click={() => dispatch('dismiss')} aria-label="Dismiss">
			<CloseIcon />
		</button>
	{/if}
</article>

<style lang="postcss">
	article {
		@apply rounded-1.5 relative m-1 flex min-w-[20rem] max-w-[min(90%,500px)] items-center px-6 py-3 text-white drop-shadow-2xl;
	}
	.error {
		@apply bg-red;
	}
	.success {
		@apply bg-green-500;
	}
	.info {
		@apply bg-orange-500;
	}
	.warning {
		@apply bg-yellow-500;
	}
	.text {
		@apply poppins-regular mx-4;
	}
	button {
		@apply text-3 m-0 ml-4 border-0 bg-transparent p-0 text-white;
	}
	.close {
		@apply absolute right-5 top-1/2 -translate-y-1/2 transform cursor-pointer;
	}
</style>
