<script lang="ts">
	import 'uno.css';
	import '../font.css';
	import '../app.css';
	import { colorInit } from '$store/darkMode';
	import { onMount } from 'svelte';
	import Toasts from '$components/atoms/toast/Toasts.svelte';

	onMount(() => {
		colorInit();
	});
</script>

<main class="pt-15 min-h-screen w-full" data-sveltekit-preload-data>
	<slot />
</main>
<Toasts />
